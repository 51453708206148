import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles} from "@mui/styles"
const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

export default Spinner;
