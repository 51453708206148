import { makeStyles} from "@mui/styles"

const useStyles = makeStyles(() => ({
  root: {
    '& .wrapper-class': {
      border: ({ error }) => `1px solid ${error ? '#F48989' : '#CDCED9'}`,
      borderRadius: 8,
      width: ({ width, fullWidth }) => (fullWidth ? '100%' : width)
    },

    '& .editor-class': {
      border: ({ error }) => `1px solid ${error ? '#F48989' : '#CDCED9'}`,
      borderRadius: '0px 0px 8px 8px',
      backgroundColor: '#ffffff',
      minHeight: ({ height }) => height,
      padding: 32
    },

    '& .toolbar-class': {
      border: ({ error }) => `1px solid ${error ? '#F48989' : '#CDCED9'}`,
      borderRadius: '8px 8px 0px 0px',
      marginBottom: 0,
      justifyContent: 'space-between'
    },

    '& .rdw-option-wrapper': {
      border: 'none'
    },

    '& .history': {}
  }
}));

export default useStyles;
