import React from "react";
import clsx from "clsx";
import { InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search as SearchIcon } from "@mui/icons-material";

const SearchBar = ({ placeholder, onSearch, ...rest }) => {
  const { className, fullWidth } = rest;
  const classes = useStyles();

  return (
    <InputBase
      fullWidth={fullWidth}
      placeholder={placeholder}
      variant="outlined"
      {...rest}
      className={clsx([classes.root, className])}
      onChange={({ target }) => onSearch(target.value)}
      startAdornment={<SearchIcon size="small" className={classes.icon} />}
    />
  );
};

export default SearchBar;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.darkInput,
    padding: "16px 14px",
    fontSize: `${14 / 16}rem`,
    borderRadius: 4,
    width: "100%",
    height: 45,

    "& input::placeholder": {
      color: theme.palette.secondary.main,
      opacity: 1,
      fontWeight: 400,
    },
  },
  icon: {
    marginRight: 5,
  },
}));
