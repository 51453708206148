import React from "react";
import clsx from "clsx";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "reusables";

const AddEventButton = (props) => {
  const history = useNavigate();

  const { className } = props;
  const classes = useStyles();
  return (
    <Button
      // eslint-disable-next-line react/style-prop-object
      style={true}
      className={clsx([classes.root, className])}
      startIcon={<AddIcon />}
      onClick={() => history("/admin/events/new")}
      {...props}
      isLoading={false}
    >
      Add Event
    </Button>
  );
};

export default AddEventButton;

const useStyles = makeStyles(() => ({
  root: {
    height: 44,
    fontSize: "1rem",
  },
}));
