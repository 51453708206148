import React, { Suspense } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackgroundImage from "../assets/auth-page-banner.png";
import { Outlet } from "react-router-dom";

const style = {
  position: "absolute",
  top: 0,
  right: 0,
  height: "100vh",
  width: "100vw",
  background: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  textAlign: "center",

  "& > main": {
    width: 360,
    maxWidth: "95%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  "& .MuiTypography-h4": {
    color: "#F5F7FA",
    fontWeight: 700,
    fontSize: "32px",
  },
  "& .MuiTypography-subtitle1": {
    color: "#FFF9E6",
    margin: "1rem auto",
  },
};

const AuthLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Box sx={style}>
      <main>
        <Typography variant="h4">EventEdge</Typography>
        <Typography variant="subtitle1">
          A one-stop gateway to a more engaging and convenient community
          management experience for every community member.
        </Typography>

        <Box className={classes.formSection}>
          <Suspense fallback={<div>Loading....</div>}>
            <Outlet />
          </Suspense>
        </Box>
      </main>
    </Box>
  );
};

export default AuthLayout;

const useStyles = makeStyles((theme) => ({
  formSection: {
    background: "white",
    color: theme.palette.text.main,
    marginTop: 24,
    borderRadius: 4,
  },
}));
