import React, { Suspense, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet, useLocation } from "react-router-dom";

const PublicLayout = ({ navcolor }) => {
  const noFooter = useLocation("/")?.isExact;
  const [search, setSearch] = useState("");
  const [hasSearch, setHasSearch] = useState(false);
  return (
    <div>
      <Header
        navcolor={navcolor}
        search={search}
        setSearch={setSearch}
        hasSearch={hasSearch}
      />
      <div style={{ minHeight: "90vh" }}>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                width: "100%",
                background: "#e3e3e3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>Loading....</h1>
            </div>
          }
        >
          <div style={{ paddingTop: "80px" }}>
            <Outlet context={[search, setHasSearch, hasSearch]} />
          </div>
        </Suspense>
      </div>
      {!noFooter && <Footer />}
    </div>
  );
};

export default PublicLayout;
