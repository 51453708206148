import React from 'react';
import { ReactComponent as Add } from '../assets/addIcon.svg';
import { Box, Typography } from '@mui/material';
import { makeStyles} from "@mui/styles"

const AddIconCard = ({ text, width }) => {
  const classes = useStyles({ width });

  return (
    <Box className={classes.root}>
      <Add />
      <Typography variant='subtitle2' className='title'>
        {text}
      </Typography>
    </Box>
  );
};

export default AddIconCard;

const useStyles = makeStyles(() => ({
  root: {
    border: '1px dashed #E5E5EA',
    width: ({ width }) => width,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 26,

    '& .title': {
      marginTop: 8
    }
  }
}));
