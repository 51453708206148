import { useState, useEffect } from 'react';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';

const useRichEditor = (initialState = '') => {
  const [convertedContent, setConvertedContent] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (initialState) {
      setConvertedContent(initialState);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(initialState))
        )
      );
    }
  }, [initialState]);

  const convertContentToHTML = (state) => {
    let currentContentAsHTML = convertToHTML(state.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const onEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML(state);
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  const getSanitizedHTML = () => {
    return createMarkup(convertedContent);
  };

  const sanitizedHTML = getSanitizedHTML();

  return { editorState, onEditorChange, sanitizedHTML };
};

export default useRichEditor;
