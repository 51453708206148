import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button as MuiButton,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";

const styles = {
  color: "#000000",
  textTransform: "capitalize",
  background: "#FDC10E",
  border: "1px solid #FDC10E",
  "&:hover": {
    background: "#BC8D00",
    border: "1px solid #FDC10E",
  },
};
const fadeBtnStyle = {
  color: "#BC8D00",
  border: "1px solid #FDC10E",
  textTransform: "capitalize",
  background: "#FFF9E6",
  "&:hover": {
    opacity: 0.9,
    border: "1px solid #FDC10E",
  },
};

const Button = ({
  children,
  loading,
  variant,
  color,
  onClick,
  style = false,
  fade = false,
  ...rest
}) => {
  const className = { rest };
  const theme = useTheme();
  const classes = useStyles({ variant, color, loading });
  const isSxDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiButton
      className={clsx([classes.root, className])}
      variant={variant}
      color={color}
      disabled={loading}
      size={isSxDown ? "small" : "medium"}
      disableElevation
      sx={style ? styles : fade ? fadeBtnStyle : {}}
      onClick={loading ? null : onClick}
      {...rest}
    >
      {children}
      {loading ? (
        <CircularProgress size={10} className={classes.progress} />
      ) : null}
    </MuiButton>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: "primary",
  variant: "contained",
  loading: false,
  onClick: () => {},
};

const useStyles = makeStyles((theme) => ({
  root: ({ loading }) => ({
    position: "relative",
    textTransform: "capitalize",
    padding: "0.5rem",
    ...(loading && { opacity: 0.8 }),
  }),
  progress: ({ variant, color }) => {
    const colors = {
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
      default: theme.palette.misc.darkSurface,
      inherit: theme.palette.primary.main,
    };
    return {
      position: "absolute",
      right: 3,
      color:
        variant === "contained" ? theme.palette.text.primary : colors?.[color],
    };
  },
}));
