export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      contained: {
        marginLeft: 5,
        fontSize: 12
      }
    }
  };
}
