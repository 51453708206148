import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';

const ControllerTextField = ({ label, control, name, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          inputRef={ref}
          onChange={onChange}
          value={value}
          label={label}
          error={!_isEmpty(error)}
          helperText={error?error.message:null}
          {...rest}
        />
      )}
    />
  );
};

export default ControllerTextField;
