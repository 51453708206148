export const PublicPaths = {
  PAYMENT: "/events/:eventId/register/:ticketId",
  EVENTS: "/events",
  EVENT_DETAILS: "/events/:eventId",
  EVENT_REGISTER: "/events/:eventId/register",
  HONOUREES: "/events/:eventId/honourees",
  HONOUREE: "/events/:eventId/honourees/:speakerId",
  SESSIONS: "/events/:eventId/sessions",
  SESSION: "/events/:eventId/sessions/:sessionId",
  COMMUNITY: "/community",
  SHOP: "/shop",
  BLOG: "/blog",
  CONTACT: "/contact",
  LOGIN: "/signin",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  LANDING: "/index",
};

// export const Entrypath = {
//   LANDING: "/index",
// };
