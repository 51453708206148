import React, { Suspense } from "react";
import { memo } from "react";
import { makeStyles } from "@mui/styles";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <aside>
        <SideBar />
      </aside>
      <main>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                width: "100%",
                background: "#e3e3e3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>Loading....</h1>
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default memo(Layout);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "no-wrap",
    width: "100%",

    "& > aside": {
      width: "17.2%",
      height: "100vh",
    },
    "& > main": {
      width: "82.2%",
      minHeight: "100%",
      height: "100vh",
      overflowY: "auto",
    },
  },
}));
