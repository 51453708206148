import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { RichEditor } from './index';
import { FormHelperText } from '@mui/material';
import useRichEditor from '../hooks/useRichEditor';
import _isEmpty from 'lodash/isEmpty';

const ControllerRichEditor = ({
  control,
  name,
  setValue,
  note,
  clearError
}) => {
  const {
    editorState,
    onEditorChange,
    sanitizedHTML: { __html: description }
  } = useRichEditor(note);

  useEffect(() => {
    setValue(name, description);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ fieldState: { error } }) => (
        <>
          <RichEditor
            editorState={editorState}
            setEditorState={(state) => {
              onEditorChange(state);
              clearError && clearError(name);
            }}
            fullWidth
            error={!_isEmpty(error)}
          />
          <FormHelperText style={{ color: '#F48989' }}>
            {error ? error.message : null}
          </FormHelperText>
        </>
      )}
    />
  );
};

export default ControllerRichEditor;
