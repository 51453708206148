import React from 'react';
import { TextField as MuiTextField } from '@mui/material';

const TextField = ({ label, error, ...rest }) => {
  const { variant } = rest;

  return (
    <MuiTextField
      label={label}
      error={!!error}
      {...(variant !== 'outlined' && {
        InputProps: {
          disableUnderline: true
        }
      })}
      // helperText here is assuming that the only helperText an input
      // needs is an error message which isn't always so, i might want to add
      // a description to an input using helperText
      helperText={error? error.message:null}
      {...rest}
    />
  );
};

export default TextField;
