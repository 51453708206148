import { makeStyles} from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: ({ width }) => width,
    flexShrink: 0,
    position: 'relative',

    '& .title': {
      fontSize: 16
    },

    '& .header': {
      padding: 24,
      position: 'sticky',
      top: 0,
      width: '100%',
      borderBottom: `1px solid ${theme.palette.grey.border}`,
      zIndex: 5000,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: '#FFFFFF',

      '& .icon': {
        padding: 8,
        fontSize: 16
      }
    },

    '& .content': {
      padding: '0 24px',
      margin: '24px 0 48px'
    },

    '& .footer': {
      backgroundColor: theme.palette.text.light,
      position: 'sticky',
      bottom: 0,
      padding: 24,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      zIndex: 5000
    },

    '& .cta': {
      marginLeft: 16
    }
  },

  paper: {
    width: ({ width }) => width
  }
}));

export default useStyles;
