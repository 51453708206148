import React from "react";
import {
  Route,
  Navigate,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes";
import { admin_routes } from "pages/admin";
import { public_routes } from "pages/public";
import { auth_routes } from "./AuthRoute";
import PublicLayout from "layout/public";
import Layout from "layout/index";
import AuthLayout from "layout/AuthLayout";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/events" replace />} />

        <Route path="/" element={<AuthLayout />}>
          {auth_routes.map((route, i) => {
            const Element = route.component;
            return <Route key={i} path={route.path} element={<Element />} />;
          })}
        </Route>

        <Route path="/" element={<PublicLayout />}>
          {public_routes.map((route, i) => {
            const Element = route.component;
            return <Route key={i} path={route.path} element={<Element />} />;
          })}
        </Route>

        <Route path="admin/" element={<Layout />}>
          {admin_routes.map((route) => {
            const Element = route.component;
            const PageComponent = ProtectedRoutes(Element);
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<PageComponent />}
              />
            );
          })}
        </Route>
        <Route path="*" element={<h1>Not found</h1>} />
      </Routes>
    </Router>
  );
};
