import { lazy } from "react";

import { PublicPaths } from "./PublicPaths";

export const auth_routes = [
  {
    path: PublicPaths.LOGIN,
    component: lazy(() => import("../pages/admin/Auth/Login")),
    layout: true,
  },
  {
    path: PublicPaths.FORGOT_PASSWORD,
    component: lazy(() => import("../pages/admin/Auth/ForgotPassword")),
    layout: true,
  },
  {
    path: PublicPaths.RESET_PASSWORD,
    component: lazy(() => import("../pages/admin/Auth/SetPassword")),
    layout: true,
  },
];
