import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import Logo from "assets/svg/logo.svg";
import { PublicPaths } from "../../routes/PublicPaths";
import { SearchBar } from "layout/Header";
import { ReactComponent as Logout } from "assets/svg/logout.svg";
// import { ReactComponent as Join } from "assets/svg/join.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const btnStyle = {
  color: "#504E48",
  background: "#FDC10E",
  padding: "0.4rem 0.8rem",
  marginRight: "1rem",
  textTransform: "capitalize",
  borderRadius: 0,
  ":hover": {
    background: "#FDC10E",
    opacity: 0.9,
  },
};

const links = [
  // { label: "Community", path: PublicPaths.COMMUNITY },
  { label: "Events", path: PublicPaths.EVENTS },
  // {
  //   label: "Shop",
  //   path: PublicPaths.SHOP,
  //   child: [{ label: "Community", path: "/" }],
  // },
  // { label: "Blog", path: PublicPaths.BLOG },
  // { label: "Contact", path: PublicPaths.CONTACT },
];

const Header = ({ navcolor, search, setSearch, hasSearch }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [colorChange, setColorchange] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const renderLinkChildren = (child) => {
    if (!child?.length) return;
    return (
      <Menu key={child.label}>
        <MenuItem>{child.label}</MenuItem>
      </Menu>
    );
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);

    return () => window.removeEventListener("scroll", changeNavbarColor);
  }, [colorChange]);

  const renderBtn = () => {
    return (
      <Box mt={isMdDown ? 2 : 0}>
        {/* <Button sx={btnStyle}>
          <Join style={{ marginRight: "0.5rem" }} /> Join
        </Button> */}
        <Button sx={btnStyle} onClick={() => navigate("/signin")}>
          <Logout style={{ marginRight: "0.5rem" }} /> Login
        </Button>
      </Box>
    );
  };

  const renderSearchBar = () => {
    if (isMdDown) return;
    return (
      <Box display="flex" alignItems="center">
        <SearchBar
          fullWidth
          value={search}
          placeholder="Search"
          onSearch={(value) => setSearch(value)}
          className={classes.searchBar}
        />
      </Box>
    );
  };

  const renderSmallNavbar = () => {
    return (
      <Box
        bgcolor={
          colorChange ? "#fff" : navcolor ? "rgba(0, 0, 0, 0.4)" : "#fff"
        }
        width={"100%"}
        margin="auto"
        display="flex"
        flexWrap="wrap"
        textAlign="start"
        sx={{ zIndex: 100, padding: "1rem 2rem" }}
        justifyContent="space-between"
        position="fixed"
      >
        <Box sx={{ position: "fixed", left: 25 }} display="flex">
          <Button sx={{ ...btnStyle }}>
            {openMenu ? (
              <MenuOpenIcon onClick={() => setOpenMenu(false)} />
            ) : (
              <MenuIcon onClick={() => setOpenMenu(true)} />
            )}
          </Button>
        </Box>
        <NavLink to={PublicPaths.EVENTS}>
          <img
            alt="logo"
            src={Logo}
            style={{ height: 60, marginLeft: "60px" }}
          />
        </NavLink>
        <Box ml={4}>{renderSearchBar()}</Box>
        {renderMenu()}
      </Box>
    );
  };

  const renderMenu = () => {
    if (!openMenu) return;
    return (
      <Box display="flex" flexDirection="column" mt={4} width="100%">
        <Box width="100%">
          {links.map((link) => {
            const isActive = window.location.href?.includes(link?.path);
            return (
              <NavLink
                to={link.path}
                key={link.label}
                style={{
                  display: "block",
                  padding: "0.9rem 1.5rem",
                  textDecoration: "none",
                  color: colorChange ? "#000" : navcolor ? navcolor : "black",
                  background: isActive ? "#FDC10E" : "#fff",
                }}
              >
                {link.label} {renderLinkChildren(link.child)}
              </NavLink>
            );
          })}
        </Box>
        {/* {renderBtn()} */}
      </Box>
    );
  };

  return (
    <div>
      {!isMdDown ? (
        <Box
          bgcolor={
            colorChange ? "#fff" : navcolor ? "rgba(0, 0, 0, 0.4)" : "#fff"
          }
          width={"100%"}
          margin="auto"
          display="flex"
          flexWrap="wrap"
          sx={{ zIndex: 100, padding: "1rem 6rem 0rem 6rem" }}
          justifyContent="space-between"
          position="fixed"
        >
          <Box>
            <NavLink to={PublicPaths.EVENTS}>
              <img alt="logo" src={Logo} style={{ height: 60 }} />
            </NavLink>
          </Box>

          <Box>
            <Box mt={2}>
              {links.map((link) => {
                const isActive = window.location.href?.includes(link?.path);
                return (
                  <NavLink
                    to={link.path}
                    key={link.label}
                    style={{
                      padding: "1.5rem",
                      textDecoration: "none",
                      color: colorChange
                        ? "#000"
                        : navcolor
                        ? navcolor
                        : "black",
                      borderBottom: isActive ? "3px solid #FDC10E" : "",
                    }}
                  >
                    {link.label} {renderLinkChildren(link.child)}
                  </NavLink>
                );
              })}
            </Box>
          </Box>
          <Box> {renderSearchBar()}</Box>
          {/* <Box>{renderBtn()}</Box> */}
        </Box>
      ) : (
        renderSmallNavbar()
      )}
    </div>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  searchBar: {
    backgroundColor: "#FFEDB2 !important",
    width: "100%",
  },
}));
