import React from "react"
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Header = ({
  subtitle,
  title,
  renderSearch,
  renderButton,
  renderTab,
  handleClick
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} justifyContent='space-between'>
      <Grid item xs={4} component='div'>
        <Typography
          variant='body2'
          onClick={handleClick}
          className='subtitle'
        >
          {subtitle}
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Grid>

      <Grid item xs={8} className={classes.searchDiv}>
        <div>{renderSearch()}</div>
        <div>{renderButton()}</div>
      </Grid>

      <Grid item xs={12}>
        {renderTab()}
      </Grid>
    </Grid>
  );
};

export default Header;

Header.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderSearch: PropTypes.func,
  renderButton: PropTypes.func,
  renderTab: PropTypes.func
};

Header.defaultProps = {
  renderSearch: () => <></>,
  renderButton: () => <></>,
  renderTab: () => <></>
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: 'white',
    padding: '24px 67px 44px 40px',
    height: 128,
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.border}`,

    '& > div > .MuiTypography-body1': {
      color: theme.palette.text.secondary,
      fontWeight: 500
    },
    '& > div > .MuiTypography-body2': {
      color: theme.palette.text.secondary,
      fontWeight: 500
    },
    '& > div > .MuiTypography-h2': {
      fontWeight: 700,
      color: theme.palette.text.dark,
      fontSize: 26,
      marginTop: 8
    },
    '& .subtitle': { cursor: 'pointer' }
  },

  searchDiv: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      display: 'block'
    },

    '& >:last-child': {
      marginLeft: 8
    }
  }
}));
