import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from "@mui/x-date-pickers";
import { ArrowDropDownRounded } from "@mui/icons-material";
import _isEmpty from "lodash/isEmpty";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const TimePicker = ({ label, name, control, fullWidth = true, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiTimePicker
            error={!_isEmpty(error)}
            label={label}
            format="HH:mm"
            variant="inline"
            inputVariant="filled"
            autoOk
            emptyLabel="Time"
            // value={value ?? null}
            value={value ? value : null}
            onChange={onChange}
            renderInput={(params) => (
              <TextField fullWidth={fullWidth} {...params} />
            )}
            // InputProps={{
            //   disableUnderline: true,
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <ArrowDropDownRounded />
            //     </InputAdornment>
            //   ),
            // }}
            ampm={false}
            helperText={error ? error.message : null}
            {...rest}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default TimePicker;
