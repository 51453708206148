import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import BoldIcon from '../../assets/BoldIcon.svg';
import ItalicsIcon from '../../assets/ItalicsIcon.svg';
import UnderlineIcon from '../../assets/UnderlineIcon.svg';
import UndoIcon from '../../assets/UndoIcon.svg';
import RedoIcon from '../../assets/RedoIcon.svg';

import useStyles from './style';

const RichEditor = ({
  editorState,
  setEditorState,
  size,
  fullWidth,
  height,
  error,
  ...rest
}) => {
  const widths = { small: 513, medium: 648, big: 736 };

  const classes = useStyles({ width: widths[size], fullWidth, height, error });

  return (
    <div className={classes.root}>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName='wrapper-class'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        toolbar={{
          options: ['inline', 'blockType', 'textAlign', 'list', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { icon: BoldIcon },
            italic: { icon: ItalicsIcon },
            underline: { icon: UnderlineIcon }
          },
          textAlign: {
            options: ['left', 'center', 'justify']
          },
          list: {
            inDropdown: true
          },
          history: {
            undo: { icon: UndoIcon },
            redo: { icon: RedoIcon },
            className: 'history'
          }
        }}
        {...rest}
      />
    </div>
  );
};

RichEditor.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'big'])
};

RichEditor.defaultProps = {
  size: 'medium'
};

export default RichEditor;
