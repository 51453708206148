import React from "react";
import { Box, Typography } from "@mui/material";
import Auth from "../utils/Auth";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { ReactComponent as MipadLogo } from "../assets/svg/MIPAD Logo.svg";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

const SideBar = () => {
  const theme = useTheme();
  const location = useLocation();

  const classes = {
    root: {
      width: "100%",
      background: theme.palette.background.darkInput,
      height: "100%",
      position: "relative",
    },
    logoBox: {
      width: "100%",
      height: "128px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontWeight: 500,
    },
    actions: {
      paddingLeft: "30px",
      position: "absolute",
      bottom: 24,

      "& > *": {
        display: "block",
      },
    },
  };

  const menuItems = [
    {
      title: "Dashboard",
      link: "/admin/events?tab=Ongoing",
      icon: DashboardOutlinedIcon,
    },
  ];

  const actions = [
    {
      title: "Account",
      icon: SettingsOutlinedIcon,
      link: window.location.pathname + window.location.search,
    },
    {
      title: "Help & Support",
      icon: SupportOutlinedIcon,
      link: window.location.pathname + window.location.search,
    },
  ];

  return (
    <Box sx={classes.root}>
      <Box sx={classes.logoBox}>
        <MipadLogo/>
        {/* <Typography variant="body2">MIPAD</Typography>
        <Typography variant="body2">Snap it and move</Typography> */}
      </Box>
      <Box p={2} sx={{ color: theme.palette.text.lightGrey }}>
        <Typography variant="body1">Menu</Typography>
      </Box>
      {menuItems?.map((menu) => {
        const isActive = !!(
          menu.link && location.pathname + location.search.includes(menu.link)
        );
        const Icon = menu?.icon;
        return (
          <Link
            to={menu.link}
            key={menu.title}
            style={{
              textDecoration: "none",
              display: "block",
              padding: "12px 16px",
              color: isActive
                ? theme.palette.text.link
                : theme.palette.text.lightGrey,
              borderRight: isActive ? "3px solid #FDC10E" : "",
            }}
          >
            <Box display="flex" alignItems="center">
              <Icon
                style={{
                  fontSize: "16px",
                  marginRight: "8px",
                  color: isActive
                    ? theme.palette.text.link
                    : theme.palette.text.lightGrey,
                }}
              />
              {menu?.title}
            </Box>
          </Link>
        );
      })}
      <Box position="absolute" bottom={24}>
        <Typography
          variant="caption"
          sx={{ display: "block", paddingLeft: "1rem" }}
        >
          YOUR ACCOUNT
        </Typography>
        {actions.map((menu, index) => {
          const isActive = !!(
            menu.link && location.pathname.includes(menu.link)
          );
          const Icon = menu?.icon;
          return (
            <Link
              to={menu.link}
              key={menu.title}
              style={{
                textDecoration: "none",
                display: "block",
                padding: "12px 16px",
                color:
                  // isActive
                  // ? theme.palette.text.link
                  // :
                  theme.palette.text.lightGrey,
                // borderRight: isActive ? "3px solid #FDC10E" : "",
              }}
            >
              <Box display="flex" alignItems="center">
                <Icon
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    color:
                      // isActive
                      //   ? theme.palette.text.link
                      //   :
                      theme.palette.text.lightGrey,
                  }}
                />
                {menu?.title}
              </Box>
            </Link>
          );
        })}
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            textDecoration: "none",
            display: "block",
            padding: "12px 18px",
            cursor: "pointer",
            color: theme.palette.text.lightGrey,
          }}
          onClick={() => Auth.logOut()}
        >
          <LogoutIcon
            style={{
              cursor: "pointer",
              fontSize: "16px",
              marginRight: "8px",
              color: theme.palette.text.lightGrey,
            }}
          />{" "}
          Logout
        </Typography>
      </Box>
    </Box>
  );
};

export default SideBar;
