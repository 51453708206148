import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Auth from "utils/Auth";

const ProtectedRoutes = (Element) => {
  const WrappedComponent = () => {
    const location = useLocation();
    const { isAuthenticated } = Auth;

    if (!isAuthenticated())
      return <Navigate to="/signin" state={{ from: location }} replace />;

    return <Element />;
  };
  return WrappedComponent;
};

export default ProtectedRoutes;
