import React from "react";
import { useState } from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { Button } from "../../reusables";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const Tabs = () => {
  const query = new URLSearchParams(useLocation().search);
  const currentTab = query.get("tab");

  const [active, setActive] = useState(!!currentTab ? currentTab : "Ongoing");

  const menuItems = ["Ongoing", "Upcoming", "Past", "Draft", "All"];
  const history = useNavigate();

  const handleClick = (item) => () => {
    setActive(item);
    history(`/admin/events?tab=${item}`);
  };

  const activeTabStyle = (item) =>
    active === item
      ? {
          borderBottom: "2px solid #FDC10E",
          fontWeight: 500,
          color: "#1E0A3C",
          ...classes.tab,
        }
      : classes.tab;

  return (
    <Box
      sx={{ ...classes.root, bottom: -1, marginTop: "10px" }}
      display="flex"
      position="absolute"
    >
      {menuItems.map((item) => (
        <Button
          key={item}
          variant="text"
          color="secondary"
          sx={() => activeTabStyle(item)}
          onClick={handleClick(item)}
          disableRipple
        >
          {item}
        </Button>
      ))}
    </Box>
  );
};

export default Tabs;

const classes = {
  root: {
    "& .MuiButton-label": {
      padding: 0,
    },

    "& .MuiButtonBase-root": {
      minWidth: 0,
      padding: 0,
      paddingBottom: "8px",
      color: "#000000",
      textTransform: "capitalize",
    },

    "& button": {
      padding: 0,
    },
  },

  tab: {
    display: "block",
    borderRadius: 0,
    marginRight: "24px",
    transition: "none",
  },
};
