import React from "react";
import {
  Drawer as MuiDrawer,
  IconButton,
  Typography,
  SvgIcon,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/closeIcon.svg";

import useStyles from "./style";
import { Button } from "../../reusables";

const Drawer = ({
  close,
  open,
  width,
  title,
  cta,
  ctaAction,
  ctaLoading,
  children,
  fontWeight
}) => {
  const classes = useStyles({ width });

  return (
    <MuiDrawer
      className={classes.drawer}
      onClose={close}
      anchor="right"
      open={open}
      classes={{ paper: classes.paper }}
    >
      <div className="header">
        <Typography variant="h5" sx={{fontWeight: fontWeight ? fontWeight : 'none'}}>{title}</Typography>
        <IconButton className="icon" onClick={close}>
          <SvgIcon
            component={CloseIcon}
            viewBox="0 0 16 17"
            fontSize="inherit"
          />
        </IconButton>
      </div>
      <div className="content">{children}</div>
      <div className="footer">
        <Button color="inherit" variant="text" onClick={close} fade>
          Cancel
        </Button>
        <Button className="cta" onClick={ctaAction} loading={ctaLoading} style>
          {cta}
        </Button>
      </div>
    </MuiDrawer>
  );
};

export default Drawer;
