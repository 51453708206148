import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import TwitterIcon from "../../assets/svg/twtr_icon.svg";
import FBIcon from "../../assets/svg/fb_icon.svg";
import InstaIcon from "../../assets/svg/insta_icon.svg";

const Footer = () => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSxDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isMdDown });

  return (
    <div>
      <div className={classes.fistLayer}></div>

      <div className={classes.mainFoot}>
        <Box
          px={isMdDown ? "1rem" : "6rem"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <div>
            <Typography
              className={classes.footerText}
              variant={"subtitle1"}
              sx={{ fontSize: isMdDown ? "10px" : isSxDown ? "10px" : "20px" }}
            >{`Copyright ${new Date().getFullYear()} MIPAD.ORG | All Rights Reserved`}</Typography>
          </div>
          <div>
            <a
              href="https://facebook.com/mipad100"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={FBIcon}
                alt="facebook"
                style={{ height: isMdDown ? "14px" : "24px" }}
              />
            </a>

            <a
              href="https://twitter.com/MIPAD100"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={TwitterIcon}
                alt="Twitter"
                style={{ height: isMdDown ? "14px" : "24px" }}
              />
            </a>

            <a
              href="https://www.instagram.com/mipad100/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={InstaIcon}
                alt="Insta"
                style={{ height: isMdDown ? "14px" : "24px" }}
              />
            </a>
          </div>
        </Box>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fistLayer: {
    height: 24,
    background: theme.palette.primary.main,
  },
  mainFoot: {
    height: 100,
    background: theme.palette.text.black,
    flexWrap: "wrap",

    "& :nth-child(2)": {
      marginLeft: "auto",
      "& :not(:last-child)": {
        marginRight: ({ isMdDown }) => (isMdDown ? 16 : 24),
      },
    },
  },
  footerText: {
    color: theme.palette.grey.background,
  },
  small: {
    paddingTop: "3rem",
    paddingBottom: "2.5rem",
  },
}));

export default Footer;
