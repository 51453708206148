function pxToRem(value) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY = `"Montserrat", sans-serif !important`; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1, // 80 / 64,
    fontSize: pxToRem(26),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1, // 64 / 48,
    fontSize: pxToRem(36),
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: pxToRem(22),
  },
  h4: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  h5: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  h6: {
    fontWeight: 500,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: "normal",
    lineHeight: 24 / 14,
    textTransform: "capitalize",
  },
};

export default typography;
