import { lazy } from "react";

export const admin_routes = [
  {
    path: "/admin/events",
    component: lazy(() => import("./Events")),
    layout: true,
  },
  {
    path: "/admin/events/:eventId",
    component: lazy(() => import("./ViewEvent")),
    layout: true,
  },
  {
    path: "/admin/events/new",
    component: lazy(() => import("./NewEvent")),
    layout: true,
  },
];
