import React from "react";
import { Autocomplete as MuiAutocomplete } from "@mui/lab";
import { TextField } from "../reusables";
import { Controller } from "react-hook-form";
import _isEmpty from "lodash/isEmpty";

const AutoComplete = ({ control, name, options, variant, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MuiAutocomplete
          value={value || null}
          getOptionSelected={(option, value) => {
            return option.value === value;
          }}
          options={options}
          onChange={(event, value) =>
            onChange(value ? value.value : null || "")
          }
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.title
          }
          // renderOption={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Country"
              variant={variant || "outlined"}
              error={!_isEmpty(error)}
              helperText={error ? error.message : null}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          {...rest}
        />
      )}
    />
  );
};

export default AutoComplete;
