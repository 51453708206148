import { lazy } from "react";

import { Entrypath, PublicPaths } from "routes/PublicPaths";

export const public_routes = [
  {
    path: PublicPaths.EVENTS,
    component: lazy(() => import("./Events")),
    layout: "public",
  },
  {
    path: PublicPaths.EVENT_DETAILS,
    component: lazy(() => import("./EventDetails")),
    layout: "public",
  },

  {
    path: PublicPaths.EVENT_REGISTER,
    component: lazy(() => import("./PriceOptions")),
    layout: "public",
  },

  {
    path: PublicPaths.HONOUREE,
    component: lazy(() => import("./HonoureeDetails")),
    layout: "public",
  },
  {
    path: PublicPaths.PAYMENT,
    component: lazy(() => import("./PaymentDetail")),
    layout: "public",
  },
  {
    path: PublicPaths.SESSION,
    component: lazy(() => import("./SessionDetails")),
    layout: "public",
  },
  {
    path: PublicPaths.COMMUNITY,
    component: lazy(() => import("./Community")),
    layout: "public",
  },
  {
    path: PublicPaths.SHOP,
    component: lazy(() => import("./Shop")),
    layout: "public",
  },
  {
    path: PublicPaths.BLOG,
    component: lazy(() => import("./Blog")),
    layout: "public",
  },
  {
    path: PublicPaths.CONTACT,
    component: lazy(() => import("./Contact")),
    layout: "public",
  },
  {
    path: PublicPaths.LANDING,
    component: lazy(() => import("./LandingPage")),
    layout: "public",
    navcolor: "white",
  },
];

// export const entry_path = [
//   {
//     path: Entrypath.LANDING,
//     component: lazy(() => import("./LandingPage")),
//   },
// ];
