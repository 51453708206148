import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  FormHelperText
} from '@mui/material';
import _isEmpty from 'lodash/isEmpty';

const Select = ({ control, name, label, menuItems }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl variant='filled' fullWidth error={!_isEmpty(error)}>
          <InputLabel htmlFor='select-filled'>{label}</InputLabel>
          <MuiSelect id='select-filled' value={value} onChange={onChange}>
            {menuItems?.map((menuItem) => (
              <MenuItem key={menuItem.value} value={menuItem.value}>
                {menuItem.label}
              </MenuItem>
            ))}
          </MuiSelect>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default Select;
