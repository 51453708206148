import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import _isEmpty from "lodash/isEmpty";

const DatePicker = ({ label, name, control, fullWidth = true, ...rest }) => {
  const classes = useStyles({ label });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            error={!_isEmpty(error)}
            label={label}
            format="MMM dd, yyyy"
            variant="inline"
            inputVariant="filled"
            autoOk
            emptyLabel="Date"
            // value={value ?? null}
            value={value ? value : null}
            onChange={onChange}
            renderInput={(params) => (
              <TextField fullWidth={fullWidth} {...params} />
            )}
            className={classes.datePicker}
            helperText={error ? error.message : null}
            {...rest}
          />
        </LocalizationProvider>
      )}
    />
  );
};

const useStyles = makeStyles({
  datePicker: ({ label }) => ({
    "& .MuiFilledInput-root": {
      ...(!label && {
        "& .MuiInputBase-input": {
          padding: "18.5px 12px 18.5px 0",
        },
      }),
    },

    "& .MuiFormLabel-filled.MuiInputLabel-filled.MuiInputLabel-shrink": {
      transform: "translate(44px, 10px) scale(0.85)",
    },

    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)":
      {
        marginTop: 8,
      },
  }),
});

export default DatePicker;
